<template>
  <v-container :fluid="$vuetify.breakpoint.smAndUp" min-height="90vh" class="pa-0 mb-12">
<!--    <GameMeAndYou/>-->
<!--    <GameInProgress/>-->
<!--    <GameType favorite/>-->
<!--    &lt;!&ndash;    <GameOfTheWeek/>&ndash;&gt;-->
<!--    <GameType :type="type" v-for="type in gameTypes" v-bind:key="type.code"/>-->
    <event-mosaic/>
    <EventNotToMiss/>
    <EventMostConsulted/>

  </v-container>
</template>

<script>

import AuthPlayerNeeded from "@/mixins/AuthPlayerNeeded";
// import GameType from "../components/game/GameType";
// import GameMeAndYou from "../components/game/GameMeAndYou";
// import GameInProgress from "../components/game/GameInPlay";
import GameMixin from "../mixins/GameMixin";
import EventMosaic from "@/components/event/EventMosaic";
import EventMostConsulted from "@/components/event/EventMostConsulted";
import EventNotToMiss from "@/components/event/EventNotToMiss";

export default {
  name: "Home",
  components: {EventNotToMiss, EventMostConsulted, EventMosaic},
  mixins: [AuthPlayerNeeded, GameMixin],
  // components: {GameInProgress, GameMeAndYou, GameType},
  data() {
    return {
      types: [
        {name: "Défis", typeCode: 'challenge'},
        {name: "Scénarios", typeCode: 'storyline'},
        {name: "Jeux de rencontre", typeCode: 'meet-game'},
      ]
    }
  },
  created() {
    // this.getGameFilters();
    // this.getGameTypes();
  }
}
;
</script>

<template>
    <v-container fluid class="mt-10 pa-0" style="max-width: 1500px">
        <v-row class="pa-0 ma-0" align-content="center" justify="center">
            <v-col cols="6" sm="3" class="pa-0 ma-0">
                <ludi-img external :src="require('../../assets/events/escape_4_4.jpg')"
                          text="Notre sélection d'escape room à retrouver" to=""/>
            </v-col>
            <v-col cols="6" sm="3" class="pa-0 ma-0">
                <ludi-img external :src="require('../../assets/events/fij_4_4.jpg')"
                          text="Notre sélection d'escape room à retrouver"/>
            </v-col>
            <v-col cols="6" sm="3" class="pa-0 ma-0">
                <ludi-img external :src="require('../../assets/events/pel_4_4.jpg')"
                          text="Notre sélection d'escape room à retrouver"/>
            </v-col>
            <v-col cols="6" sm="3" class="pa-0 ma-0">
                <v-row class="pa-0 ma-0">
                    <v-col cols="6" class="pa-0 ma-0">
                        <ludi-img external :src="require('../../assets/events/ludinord_2_4.jpg')"
                                  text="Notre sélection d'escape room à retrouver" small/>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0">
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" class="pa-0 ma-0">
                                <ludi-img external :src="require('../../assets/events/ludimania_1_4.jpg')"
                                          text="Notre sélection d'escape room à retrouver" small/>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 ma-0">
                            <v-col cols="12" class="pa-0 ma-0">
                                <ludi-img external :src="require('../../assets/events/ludinam_1_4.jpg')"
                                          text="Notre sélection d'escape room à retrouver" small/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LudiImg from "../LudiImg";

    export default {
        name: "EventMosaic",
        components: {LudiImg},
        data: () => ({}),
        computed: {}
    }
</script>

<style scoped>

</style>
<template>
  <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="400"
      :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-img src="~@/assets/ludievents-banner.png" contain dark class="pa-5 ma-5"/>
      <v-card-title class="headline">
        Voulez-vous installer Private Games en tant qu'application ?
      </v-card-title>
      <v-card-text>
        Une application Progressive Wep App (PWA) est un format léger d'application avec les mêmes contenus et
        fonctionnalités que sur un store classique, mais avec beaucoup moins d'encombrement.
        <br>Fonctionne sur PC/MAC/Android/iPhone/iPad/...
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="pg-color-very-dark-pink"
            dark
            text
            @click="addToHomeScreen"
        >
          Oui super !
        </v-btn>
        <v-btn
            text
            @click="refuseHomeScreen"
        >
          Pas pour le moment
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";
import PopUpMixin from "../mixins/PopUpMixin";
import AuthPlayerNeeded from "../mixins/AuthPlayerNeeded";

const {mapFields} = createHelpers({
  getterType: 'pwa/getField',
  mutationType: 'pwa/updateField'
});

export default {
  name: "PWAManager",
  mixins: [PopUpMixin, AuthPlayerNeeded],
  data: function () {
    return {
      dialog: false,
      registration: null,
      updateExists: false,
      refreshing: false,
    }
  },
  computed: {
    ...mapFields(['isPWAReady', 'lastAsked', 'hasUpdate']),
  },
  methods: {
    ...mapActions({
      updateLastAskedPWA: "pwa/updateLastAskedPWA",
    }),
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true;
      this.showPopUpUpdate();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({type: 'SKIP_WAITING'})
    },
    showPopUpUpdate() {
      this.showPopUp({
        title: "Mise à jour de l'application",
        message: 'Une nouvelle version de l\'application est disponible, cliquez ici pour mettre à jour !',
        buttonTitle: 'Mettre à jour',
        callback: this.refreshApp
      })
    },
    addToHomeScreen() {
      // Show the prompt
      this.dialog = false;
      this.isPWAReady.prompt();
      // Wait for the user to respond to the prompt
      this.isPWAReady.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.isPWAReady = null;
          });
    },
    refuseHomeScreen() {
      this.dialog = false;
      this.isPWAReady = null;
    },
    isPWAToShow() {
      if (!this.isTutorialDone) {
        return false;
      }
      if (!this.lastAsked) {
        return true;
      }
      let dateDiff = Date.now() - this.lastAsked;
      return parseInt(dateDiff / (24 * 3600 * 1000)) > 6;
    },
  },
  created() {
    //https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
    console.log("Adding event listener: checking PWA");
    window.addEventListener('beforeinstallprompt', e => {
      console.log('App is PWA ready')
      e.preventDefault();
      this.isPWAReady = e;
      if (this.isPWAToShow()) {
        this.dialog = true;
        this.updateLastAskedPWA();
      }
    });
    document.addEventListener('swUpdated', this.updateAvailable, {once: true});
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
  beforeDestroy() {

  }

}
</script>

<style scoped>

</style>
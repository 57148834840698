import Vue from "vue";
import Vuex from "vuex";

import {auth} from './modules/auth.module.js';
import {avatar} from "./modules/avatar.module";
import {message} from "@/store/modules/message.module";
import {navigation} from "@/store/modules/navigation.module";
import {pwa} from "@/store/modules/pwa.module";
import {player} from "@/store/modules/player.module";
import {game} from "./modules/game.module";
import {game_in_play} from "./modules/game_in_play.module";
import {game_write} from "./modules/game_write.module";
import {current_game} from "./modules/current_game.module";
import {notification} from "./modules/notification.module";
import {mercure} from "./modules/mercure.module";
import {pop_up} from "./modules/pop_up.module";
import {geo} from "./modules/geo.module";
import {shop} from "./modules/shop.module"
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({isCompression:false});

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        avatar,
        message,
        navigation,
        pwa,
        player,
        current_game,
        notification,
        game,
        game_in_play,
        game_write,
        mercure,
        pop_up,
        geo,
        shop
    },
    plugins: [createPersistedState({
        key: 'pg-client-vuex',
        storage: {
            getItem: (key) => {
                ls.get(key);
            },
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        },
        reducer(val) {
            if (!val.auth.token) {
                return {};
            } else {
                return val;
            }
        }
    })]
});

<template>
  <v-app v-scroll="onScroll">
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-main fill-height  class="ludi-blue">
      <v-btn fab right fixed :to="{name:'Home'}" ><v-icon color="blue">mdi-home</v-icon></v-btn>
      <v-row no-gutters align-content="center" justify="center">
          <v-container :fluid="$vuetify.breakpoint.mdAndUp" class="pa-0">
            <router-view/>
          </v-container>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import LayoutMixin from "../mixins/LayoutMixin";
import AuthPlayerNeeded from "../mixins/AuthPlayerNeeded";
import MessageMixin from "../mixins/MessageMixin";


export default {
  name: "AuthLayout",
  components: {},
  mixins: [LayoutMixin, AuthPlayerNeeded, MessageMixin],
  computed:{
  }
}
</script>

<style scoped>

</style>
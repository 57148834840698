<template>
    <v-footer dark class="ludi-footer" >
        <v-row justify="center" >
            <v-col cols="6" sm="4" md="3">
                <h3> {{'Catégorie'}} : </h3>
                <ul>Pouette</ul>
            </v-col>
            <v-col cols="6" sm="4" md="3"><h3> {{'Légal'}} : </h3>
                <ul>
                    <li><a href="#"> Qui sommes nous </a></li>
                    <li><a href="#"> Mentions légales </a></li>
                    <li><a href="#"> Conditions générales de vente </a></li>
                    <li><a href="javascript:tarteaucitron.userInterface.openPanel();">cookies_management</a></li>
                </ul>
            </v-col>
<!--            <v-col cols="6" sm="4" md="3"><h3> {{'Changer de langue'}} : </h3>-->
<!--                <ul>-->
<!--                    <li>-->
<!--                        <v-btn title="Français" text @click.prevent="$i18n.locale='fr'">-->
<!--                            <flag iso="fr" class="mr-3"/>-->
<!--                            Français-->
<!--                        </v-btn>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <v-btn title="English" text @click.prevent="$i18n.locale='en'">-->
<!--                            <flag iso="gb" class="mr-3"/>-->
<!--                            English-->
<!--                        </v-btn>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </v-col>-->
            <v-col cols="12" sm="6" md="3"><h3> {{'Nous suivre'}} </h3>
                <ul class="social">
                    <li>
                        <v-btn icon href="https://www.facebook.com/ludieventsofficiel/" target="_blank">
                            <v-icon class="social-icon">mdi-facebook</v-icon>
                        </v-btn>
                    </li>
                    <li>
                        <v-btn icon href="#"><i class="fab fa-twitter"> &nbsp; </i>
                            <v-icon class="social-icon">mdi-twitter</v-icon>
                        </v-btn>
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
    export default {
        name: "LudiFooter"
    }
</script>

<style scoped>
    h3 {
        padding: 40px 0 10px;
        color: #54697E;
        border-bottom: 1px solid #BAC1C8;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
    }

    a, button {
        color: #78828D !important;
        text-decoration: none;
    }

    ul {
        margin-top: 15px;
        margin-left: 0;
        padding-left: 0;
        color: #7F8C8D;
        font-size: 13px;
        list-style-type: none;
    }

    .ludi-footer {
        border-top: 1px solid #DDDDDD !important;
        border-bottom: 1px solid #CCCCCC !important;
        background-color: black !important;
    }

    .social {
        list-style: none;
        display: inline;
    }

    .social li {
        width: 36px;
        height: 36px;
        float: left;
        margin: 5px 8px 0 0;
        padding: 0;
        border: 2px solid #B5B5B5;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 36%;
        background: none repeat scroll 0 0 #008cba;
        text-align: center;
        line-height: 36px;
        -moz-transition: all 0.5s ease 0s;
        -ms-transition: all 0.5s ease 0s;
        -o-transition: all 0.5s ease 0s;
        -webkit-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
    }

    .social-icon {
        margin: 8px 8px 8px 8px;
        color: ghostwhite !important;
        font-size: 24px !important;
    }

    .social li:hover {
        -moz-transform: scale(1.1) rotate(360deg);
        -ms-transform: scale(1.1) rotate(360deg);
        -o-transform: scale(1.1) rotate(360deg);
        -webkit-transform: scale(1.1) rotate(360deg);
        transform: scale(1.15) rotate(360deg);
    }
</style>
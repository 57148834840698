/* eslint-disable no-console */

import { register } from 'register-service-worker'

navigator.serviceWorker.register('/push_worker.js', {
    scope: '/push'
});

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
      console.log("Push Service Worker Loaded...");
      self.addEventListener("push", e => {
        const data = e.data.json();
        self.registration.showNotification(
            data.title, // title of the notification
            {
              body: data.body, //the body of the push notification
              image: './img/icons/android-icon-192x192.png',
              icon: './img/icons/android-icon-192x192.png', // icon
              vibrate: [200, 100, 200, 100, 200, 100, 200],
              tag: 'game-notification'
            }
        );
      });
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
          new CustomEvent('swUpdated', {detail: registration})
      )
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
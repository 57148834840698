<template>
  <v-container>
    <v-card class="mx-auto ludi-light-white" max-width="1200px" elevation="30"
            style="box-shadow: 3px 3px 9px 0px #000000;">
      <v-card-title class="text-uppercase"><h1 class="mx-auto">
        {{ 'most_viewed' }}
      </h1></v-card-title>
      <v-progress-linear
          indeterminate
          color="blue"
          v-if="isLoading"
      ></v-progress-linear>
      <v-row dense v-if="!isLoading">
        <v-col
            v-for="event in items"
            :key="event.shortName"
            cols="4"
        >
          <v-card>
            <ludi-img
                :src="event.cover?event.cover.contentUrl:require('../../assets/events/default_logo.jpg')"
                :external="event.cover?false:true"
                :text="event.completeName"
                height="200px"

            />
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon @click="showHandler(event)">
                <v-icon>mdi-heart</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>

              <v-btn icon>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row dense justify="center" align-content="center">
          <v-btn dark class="ml-1 mr-1" color="ludi-blue" @click.prevent="refreshAll(i*3)" v-for="i in 4" :key="i">
            {{ i * 3 }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import LudiImg from "../LudiImg";

export default {
  name: "EventMostConsulted",
  components: {LudiImg},
  data: () => (
      {
        items: [{
          shortName: "Ludinam",
          cover: "",
          completeName: " Ludinam 2022: retour en enfance!"
        }]
      }
  ),
  created() {

  },
  computed: {},
  methods: {}
}
</script>

<style scoped>

</style>
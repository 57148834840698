import {createHelpers} from 'vuex-map-fields';

const {mapFields} = createHelpers({
    getterType: 'player/getField',
    mutationType: 'player/updateField'
});

export default {
    computed: {
        ...mapFields({
            teams: 'teams',
            playerId: 'playerId',
            nickName: 'nickName',
            playerAvatar:'avatar',
            avatarSavedImage : 'avatarSavedImage',
            awardPoints: 'awardPoints',
            gameCreditsCoins: 'gameCreditsCoins',
            playerHydraId: '@id',
            receivedInvitations: 'receivedInvitations',
            sendedInvitations: 'sendedInvitations',
            nbGamesForToday:'nbGamesForToday'
        }),
        you() {
            if (this.couple && this.couple.players.length) {
                return this.couple.players.find(player => player.id != this.playerId);
            }
            return null;
        },
        couple(){
            return this.teams.find(team => team.maximumPlayersNumber == 2)
        },
        isSolo() {
            return Object.keys(this.you).length === 0;
        },
        avatarImagePath() {
            return this.getAvatarImagePath(this.avatarSavedImage);
        },
        youAvatarImagePath() {
            return this.getAvatarImagePath(this.you.avatarSavedImage);
        },

    }, methods:{
        getPlayerAvatarImagePath(player){
            return this.getAvatarImagePath(player.avatarSavedImage);
        },
        getAvatarImagePath(image){
            if( image ){
                return process.env.VUE_APP_API_URL + image.contentUrl;
            }
            return require('@/assets/ludi-events.png');
        }
    }
}
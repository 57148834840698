import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Shop from "@/views/Shop";
import goTo from "vuetify/es5/services/goto";
import game from "./game";
import Chat from "../views/Chat";
import auth from "./auth";
import profile from "./profile";
import play from "./play";
import Base from "../layouts/Base";
import store from '../store'
import vuetify from "../plugins/vuetify";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Base,
        redirect: '/home',
        children: [
            {
                path: "/home",
                name: "Home",
                component: Home
            },
            {
                path: "/chat",
                name: "Chat",
                component: Chat,
            },
            {
                path: "/shop",
                name: "Shop",
                component: Shop,
            },
            {
                path: "/cgv",
                name: "CGV",
                component: () => import(/* webpackChunkName: "CGV" */ '../views/CGV.vue'),
            }
            , game, profile
        ]
    },
    auth, play,
    {
        path: "*",
        component: Home
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to === from) return null;
        let scrollTo = 0;
        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return goTo(scrollTo)
    },
    routes
});
window.popStateDetected = false
window.addEventListener('popstate', () => {
    window.popStateDetected = true
})

router.beforeEach((to, from, next) => {
        const token = localStorage.getItem('token');
        const isItABackButton = window.popStateDetected
        const isALoginRoute = to.name == 'Login' || to.name == 'Verification' || to.name == 'Register' || to.name == 'Recovery'
        if (!isALoginRoute && !token) {
           // next({name: 'Login'})
            next();
        } else {
            if (isALoginRoute && token) {
                next(false);
            }
            if (isItABackButton) {
                if (store.state.notification.showNotifications) {
                    store.state.notification.showNotifications = false;
                    next(false)
                }
                if (store.state.game.showGameToStart) {
                    store.state.game.showGameToStart = false;
                    next(false)
                }
                if (store.state.game.gameInvitationToAnswer) {
                    store.state.game.gameInvitationToAnswer = false;
                    next(false)
                }
                if (store.state.navigation.showNavigation) {
                    if (!vuetify.framework.breakpoint.mdAndUp) {
                        store.state.navigation.showNavigation = false;
                        next(false);
                    }
                }
                if (store.state.pop_up.dialog) {
                    store.state.pop_up.dialog = false;
                    next(false);
                }
                window.popStateDetected = false;
                next(to);

            } else {
                next();
            }
        }
    }
);

export default router;

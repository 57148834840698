<template>
  <div>
    <v-list>
      <v-list-item class="px-2" :to="{name:'ProfileMe'}">
        <v-list-item-avatar>
          <v-img :src="avatarImagePath"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>{{ firstName }} {{ lastName }}</v-list-item-content>
      </v-list-item>
      <v-divider/>
    </v-list>
    <v-list>
      <v-list-item-group
          v-model="selectedItem"
          color="primary"
      >
        <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"/>
          </v-list-item-content>
        </v-list-item>
        <!--        <v-list-item @click="startTutorial">-->
        <!--          <v-list-item-icon>-->
        <!--            <pg-icon MD class="ma-0">i-play</pg-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-content v-text="'Tutoriel'"/>-->
        <!--        </v-list-item>-->
        <v-list-item
            href="https://docs.google.com/forms/d/1zIjvGWGr1xUBvPwHkudu02rKlOGpsKVvk_SpvezRL2I/viewform?edit_requested=true"
            target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-chat-processing</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Nous contacter</v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout" v-if="loggedIn">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-text="'Déconnexion'"/>
        </v-list-item>
        <v-list-item :to="{name:'Login'}" v-else>
          <v-list-item-icon>
            <v-icon>mdi-login-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content v-text="'Connexion'"/>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import AuthPlayerNeeded from "@/mixins/AuthPlayerNeeded";
import {createHelpers} from "vuex-map-fields";
import PlayerMixin from "../mixins/PlayerMixin";
import NavigationMixin from "../mixins/NavigationMixin";

const {mapFields} = createHelpers({
  getterType: 'player/getField',
  mutationType: 'player/updateField'
});
export default {
  name: "Menu",
  mixins: [AuthPlayerNeeded, PlayerMixin, NavigationMixin],
  computed: {
    ...mapFields(['firstName', 'lastName'])
  },
  data() {
    return {
      selectedItem: "",
      items: [
        {
          icon: 'mdi-home',
          title: 'Accueil',
          link: {name: 'Home'}
        },
        {
          icon: 'mdi-cart',
          title: 'Boutique',
          link: "/shop"
        }]
    }
  }, methods: {
    startTutorial() {
      this.showNavigation = false;
      this.isTutorialDone = false
      if (this.$route.name !== 'Home') {
        this.$router.push({name: 'Home'})
      }
    },
  }
}
</script>

<style scoped>

</style>
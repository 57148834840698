<template>
  <v-container fluid class="pa-0">
    <v-container fluid class="pa-0 fill-height">
      <v-row no-gutters justify="center" align="center">
        <v-img src="~@/assets/ludievents-banner.png" contain class="mb-16 ml-sm-2" max-width="800px" max-height="30vh"/>
      </v-row>
      <v-row justify="center" no-gutters class="pt-sm-2 pt-12 pb-sm-2 pb-12" align="center">
        <v-col cols="11" sm="6" class=" pa-0 pa-md-12">
          <v-form ref="form_recovery_ask" v-model="validForm" v-if="token === undefined">
            <v-btn class="pg-color-white mb-8" block :disabled="loading" @click="token=''">J'ai déjà mon token de
              récupération
              <v-progress-circular
                  indeterminate
                  color="purple"
                  class="ml-1"
                  v-if="loading"
              ></v-progress-circular>
            </v-btn>
            <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
                class="pg-text-field pg-color-black-input-transparent"
                dark
                outlined
                required
            />
            <v-btn class="pg-color-white" block :disabled="loading || !validForm" @click="handleAskRecovery">Récupérer
              mon
              mot de passe
              <v-progress-circular
                  indeterminate
                  color="purple"
                  class="ml-1"
                  v-if="loading"
              ></v-progress-circular>
            </v-btn>
          </v-form>
          <v-form ref="form_recovery_change" v-else>
            <v-text-field
                v-model="token"
                label="Mon token de vérification de changement de mot de passe"
                class="pg-text-field transparent"
                outlined
                required
                rounded
                dark
                clearable
                placeholder=" "
                ref="token"
            ></v-text-field>
            <v-text-field
                v-model="password"
                dark
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Votre mot de passe"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
                class="pg-text-field pg-color-black-input-transparent"
                outlined
                required
            />
            <v-text-field
                v-model="passwordConfirm"
                dark
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirmer votre mot de passe"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
                class="pg-text-field pg-color-black-input-transparent"
                outlined
                required
            />
            <v-btn class="pg-color-white" block @click="handlePasswordRecovery">Valider nouveau mot de passe
            </v-btn>
          </v-form>

          <v-row class="mt-12" no-gutters>
            <v-col lg="6" offset-lg="3" class="white--text">
              Vous avez déjà un compte ? <br> Connectez vous en
              <v-btn dark :to="{name:'Login'}">cliquant ici !</v-btn>
            </v-col>
          </v-row>
          <v-alert
              :type=messageType
              dismissible
              v-model="showAlert"
          >{{ message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import MessageMixin from "../../mixins/MessageMixin";
import AuthPlayerMixin from "../../mixins/AuthPlayerMixin";

export default {
  name: "Recovery",
  mixins: [AuthPlayerMixin, MessageMixin],
  data() {
    return {
      token: undefined,
    }
  },
  methods: {
    handleAskRecovery() {
      this.loading = true;
      if (this.email) {
        this.$store.dispatch('auth/recoveryAsk', {
          email: this.email.trim(),
        }).then(
            () => {
              this.loading = false;
              this.showSuccessMessage('Un mail est parti avec la procédure de récupération du mot de passe.');
              this.$router.push({name: "Login"})
            }).catch(
            error => {
              this.loading = false;
              console.error(error.response)
              this.message = error.response.data;
              this.showErrorMessage(this.message);
            });
      } else {
        this.loading = false;
        this.showErrorMessage("Il faut rentrer le mail.");
      }
    },
    handlePasswordRecovery() {
      this.loading = true;
      if (this.token) {
        this.$store.dispatch('auth/passwordRecovery', {
          token: this.token,
          password: this.password
        }).then(
            () => {
              this.loading = false;
              this.showSuccessMessage('Mot de passe mis à jour avec succès.');
              this.$router.push({name: "Login"})
            }).catch(
            error => {
              this.loading = false;
              console.error(error.response)
              this.message = error.response.data;
              this.showErrorMessage(this.message);
            });
      } else {
        this.loading = false;
        this.showErrorMessage("Il faut rentrer le token.");
      }
    }
  }
  ,
  created() {
    this.token = this.$route.query.token;
  }
  ,
  watch: {
    'email':

        function () {
          this.email = this.email.toLowerCase().trim();
        }
  }
}
</script>

<style scoped>

</style>
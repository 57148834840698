<template>
  <v-container fluid class="pa-0">
    <v-container fluid class="pa-0 mt-md-16 mt-16">
      <v-row no-gutters justify="center" align="center">
          <v-img src="~@/assets/ludievents-banner.png" contain class="mb-16 ml-sm-2" max-width="800px" max-height="30vh"/>
      </v-row>
      <v-row justify="center" no-gutters class="pt-sm-2 pt-12 pb-sm-2 pb-12">
        <v-col cols="11" sm="6" class=" pa-0 pa-md-12">
          <v-form ref="form" v-model="validForm" class="ma-1 ma-md-10" lazy-validation @submit.prevent="handleLogin">
            <v-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
                class="pg-text-field pg-color-black-input-transparent"
                outlined
                required
                rounded
                dark
                clearable
                placeholder=" "
                ref="login"
            />
            <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Votre mot de passe"
                hint="At least 8 characters"
                counter
                @click:append="show1 = !show1"
                class="pg-text-field pg-color-black-input-transparent"
                outlined
                required
                rounded
                dark
                placeholder=" "
                ref="password"

            />
            <v-btn class="pg-color-white-cream" block :disabled="loading || !validForm"  type="submit">Se
              connecter
              <v-progress-circular
                  indeterminate
                  color="purple"
                  class="ml-1"
                  v-if="loading"
              ></v-progress-circular>
            </v-btn>
          </v-form>
          <v-row class="white--text pt-8 pt-sm-10" align="center" justify="center">
            <v-col cols="12" class="text-center">Pas encore de compte ?</v-col>
            <v-col align-self="center" class="text-center">
              <v-btn :to="{name:'Register'}" class="mr-3">Insrivez-vous ici</v-btn>
              <v-btn :to="{name:'Verification'}" class="ml-3">Vérifier mon adresse mail</v-btn>
              <v-btn :to="{name:'Recovery'}" class="ml-3">Récupérer mon mot de passe</v-btn>
            </v-col>
          </v-row>
          <v-alert
              :type=messageType
              dismissible
              v-model="showAlert"
          >{{ message }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import AuthPlayerMixin from "@/mixins/AuthPlayerMixin";
import MessageMixin from "@/mixins/MessageMixin";

export default {
  name: "Login",
  mixins: [AuthPlayerMixin, MessageMixin],
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.$store.dispatch('auth/login', {email: this.email, password: this.password}).then(
            () => {
              this.showInfoMessage("Connexion réussie !");
              let direction = 'Home';
              if (this.from.name != null && this.from.name.length != 0
                  && this.from.name != 'Login'
                  && this.from.name != 'Register'
                  && this.from.name != 'Verification'
                  && this.from.name != 'Recovery') {
                direction = this.from.name;
              }
              this.$router.push({name: direction, params: this.from.params});

            }).catch(error => {
          this.loading = false;
          console.error(error);
          if (error.status) {
            switch (error.status) {
              case 401: {
                this.showErrorMessage("Le login et le mot de passe sont incorrects");
              }break;
              case 403: {
                this.showErrorMessage(error.data.message);
              }
                break;
              default:
                this.showErrorMessage("Une erreur est arrivée, merci de réessayer");
            }
          }
        })
        ;
      } else {
        this.loading = false;
        this.showErrorMessage("Il faut rentrer le mail et le mot de passe")
      }
    },
  },
  mounted() {
    this.$refs.login.focus();
  },
  watch: {
    'email': function () {
      this.email = this.email.toLowerCase().trim();
    }
  }
}
</script>

<style scoped>

</style>
import {createHelpers} from 'vuex-map-fields';
import {mapActions} from "vuex";
import GameService from "../services/game.service";

const {mapFields} = createHelpers({
    getterType: 'game/getField',
    mutationType: 'game/updateField'
});

export default {
    created() {
        if (!this.gameTypes.length) {
            this.getGameTypes();
        }
    },
    computed: {
        ...mapFields(['gameToStart', 'currentGames', 'gameInvitationToAnswer', 'gameFilters', 'gameTypes', 'gamesByType',
            'gameInvitationPlayInGameId', 'showGameToStart', 'gamePage', 'gamesPerPage', 'totalGames', 'selectedGameFilters']),
        nbCurrentGames() {
            return this.currentGames.length;
        },
        isGameToStartGameOfWeek() {
            return (this.gameToStart != null && this.gameToStart.isGameOfWeek);
        },
        baseUrl: () => {
            return process.env.VUE_APP_API_URL;
        },
        defiGameType() {
            return this.gameTypes.find(gameType => gameType.code === "defi");
        }
    },
    methods: {
        getGameImageUrl(game) {
            if (game && game.image) {
                return this.baseUrl + game.image.contentUrl;
            }
            return require('../assets/ludi-events.png');
        },
        showInvitationGame(playInGameId, notification) {
            this.setGameInPlayNotificationInvitation(notification);
            this.gameInvitationPlayInGameId = playInGameId;
            this.gameInvitationToAnswer = true;
        },
        async getGamesByType(gameTypeCode, gameFilters = [], pagination = {
            page: 1,
            itemsPerPage: 20
        }, isCommunity = false, isFavorite = false) {
            if (gameTypeCode === 'community') {
                gameTypeCode = null;
                isCommunity = true;
            }
            if (isCommunity) {
                gameTypeCode = null;
            }
            let games = [];
            await GameService.getGames(gameTypeCode, gameFilters, null, pagination, isCommunity, isCommunity, isFavorite).then(response => {
                this.totalGames = response.data['hydra:totalItems'];
                games = response.data['hydra:member'];
            }).catch(error => {
                return Promise.reject(error);
            });
            return Promise.resolve(games);
        },
        async loadGamesByTypes() {
            let pagination = {
                page: this.gamePage,
                itemsPerPage: this.gamesPerPage
            }
            let gameType = this.$route.params.typeCode;
            let favorite = false;
            if (gameType === 'favorite') {
                gameType = null;
                favorite = true;
            }
            return new Promise((resolve, reject) => {
                this.getGamesByType(gameType, this.selectedGameFilters, pagination, false, favorite).then((games) => {
                    this.gamesByType = games;
                    return resolve(games);
                }).catch(() => {
                    return reject;
                });
            })
        },
        async loadCommunityGames() {
            let pagination = {
                page: this.gamePage,
                itemsPerPage: this.gamesPerPage
            }
            return new Promise((resolve, reject) => {
                let typeCode = null;
                this.getGamesByType(typeCode, this.selectedGameFilters, pagination, true).then((games) => {
                    this.gamesByType = games;
                    return resolve(games);
                }).catch(() => {
                    return reject;
                });
            })
        },
        ...mapActions({
            getGameFilters: "game/getGameFilters",
            getGameTypes: "game/getGameTypes",
            setGameInPlayNotificationInvitation: "game_in_play/setGameInPlayNotificationInvitation",
            clearSelectedGameFilters: 'game/clearSelectedGameFilters'
        })
    },
    watch: {
        '$route': function () {
            this.clearSelectedGameFilters();
        }
    }

}
import AvatarService from '../services/avatar.service'

export default {
    methods: {
        getImagePath(imgUrl) {
            return process.env.VUE_APP_API_URL + imgUrl;
        },
        getAvatarUrlForPlayer( player) {
            if(player.avatarSavedImage && player.avatarSavedImage.contentUrl)
            {
                return this.getImagePath(player.avatarSavedImage.contentUrl);
            }
            return require('@/assets/ludi-events.png');
        },
        async getAvatar(avatarHydraId) {
            return new Promise((resolve, reject) => {
                AvatarService.loadAvatar(avatarHydraId).then((response) => {
                    return resolve(response.data);
                }).catch((error) => {
                    console.error(error);
                    return reject(error);
                });
            })

        }
    }
}
<template>
  <div class="fill-height">
    <v-row no-gutters justify="center" align="center">
      <v-img src="~@/assets/ludievents-banner.png" contain class="mb-16 ml-sm-2" max-width="800px" max-height="30vh"/>
    </v-row>
    <v-container fluid class="pa-0">
      <v-row class="text-center mt-12 pt-12 pb-12" no-gutters>
        <v-col lg="8" offset-lg="2" align-self="center" v-if="query" class="white--text">
          Vérification en cours : {{ token }}
          <v-progress-linear
              :indeterminate="query"
          ></v-progress-linear>
        </v-col>
        <v-col lg="8" offset-lg="2" align-self="center" v-else class="white--text">
          <template v-if="!showLogin">
            Vous pouvez essayer de rentrer manuellement votre token :
            <v-form ref="form_verify">
              <v-text-field
                  v-model="token"
                  label="Mon token de vérification de mail"
                  class="pg-text-field transparent"
                  outlined
                  required
                  rounded
                  dark
                  clearable
                  placeholder=" "
                  ref="token"
              ></v-text-field>
              <v-btn class="pg-color-white" block @click="handleVerify">Valider le
                token
              </v-btn>
            </v-form>
          </template>
          <v-row class="mt-12" no-gutters>
            <v-col lg="6" offset-lg="3" class="white--text">
              <br> Connectez vous en
              <v-btn :to="{name:'Login'}">cliquant ici !</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import MessageMixin from "@/mixins/MessageMixin";

export default {
  name: "Verification",
  mixins: [MessageMixin],
  data() {
    return {
      query: false,
      infoMessage: "",
      infoType: "success",
      token: "",
      showLogin: false
    }
  },
  methods: {
    ...mapActions({
      verifyToken: "auth/verify",
    }),
    handleVerify() {
      if (this.token && this.token.length > 0) {
        this.query = true;
        this.verifyToken(this.token).then(() => {
          this.showLogin = true;
          this.showSuccessMessage("Votre token est validé !")
        }).catch(() => {
          this.showErrorMessage("Une erreur est survenue ! Le token n'est pas valide.");
        }).finally(() => {
          this.query = false;
        })
      } else {
        this.query = false;
        this.showInfoMessage("Le token n'est pas renseigné");
      }
    }
  },
  created() {
    this.token = this.$route.query.token;
    this.handleVerify();
  }
}
</script>

<style scoped>

</style>
<template>
  <v-app v-scroll="onScroll">
    <!--    <tutorial-frame class="show-over-avatar-over" v-if="playerId"/>-->
    <!--    <game-starter class="show-over-avatar-over"/>-->
    <!--    <game-play-invite class="show-over-avatar-over"/>-->
    <!--    <game-write class="show-over-avatar-over"/>-->
    <notification class="show-over-avatar-over"/>
    <!--    <payment/>-->
    <v-snackbar
        v-model="showSnackBar"
        :color="snackBarColor"
        dark
        app
        width="100vw"
        class="show-over-avatar-over"
    >
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            @click="showSnackBar = false"
            v-bind="attrs"
        >
          <v-icon>mdi-close</v-icon>
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer :expand-on-hover="$vuetify.breakpoint.mdAndUp" app clipped
                         v-model="showNavigation" class="show-over-avatar-over">
      <Menu/>
    </v-navigation-drawer>
    <v-app-bar class="ludi-app-bar show-over-avatar" clipped-left
               color="black"
               v-show="!isWritingGame"
               elevation="20"
               dark
               app>
      <v-card max-height="64px" color="transparent" class="text-center">
        <v-row no-gutters align="start" justify="start">
          <v-img
              alt="Ludi-Events Logo"
              class="pa-0 ma-0 mr-auto"
              src="~@/assets/ludievents-banner.png"
              transition="scale-transition"
              height="64px"
              width="185px"
              contain
          />
          <v-card-text class="pa-0 ma-0 mt-1 punchline">{{ 'La référence pour sortir jouer !' }}
          </v-card-text>
        </v-row>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn icon small :to="{name:'ProfileMe'}">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-btn icon small>
        <v-badge
            color="ludi-blue"
            overlap
            :content="0"
            :dot="true"
        >
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
      <v-btn icon small @click="showNotifications=!showNotifications">
        <v-badge
            color="ludi-blue"
            :content="nbNotificationToRead"
            overlap
            :dot="nbNotificationToRead<=0"
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
      <v-btn icon @click="showNavigation=!showNavigation" v-show="$vuetify.breakpoint.smAndDown">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main class="mb-16">
      <checkout/>
      <v-row no-gutters align-content="center" justify="center">
        <v-container :fluid="$vuetify.breakpoint.mdAndUp" class="pa-0" style="max-width: 1446px; min-height: 100vh">
          <router-view/>
        </v-container>
      </v-row>
    </v-main>
    <ludi-footer/>
    <v-footer dark max-height="24px" class="ml-12">
      <v-col cols="12" class="caption ma-0 pa-0">Copyright0.1.0 - <a href="https://www.jinic-media.com" target="_blank">Jinic
        Media</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import AuthPlayerNeeded from "@/mixins/AuthPlayerNeeded";
import MessageMixin from "@/mixins/MessageMixin";
import Menu from "@/components/Menu";
import NavigationMixin from "@/mixins/NavigationMixin";
import Notification from "@/components/Notification";
import NotificationMixin from "@/mixins/NotificationMixin";
import LayoutMixin from "../mixins/LayoutMixin";
import GameInPlayMixin from "../mixins/GameInPlayMixin";
import MercureMixin from "../mixins/MercureMixin";
import AuthPlayerMixin from "../mixins/AuthPlayerMixin";
import {mapGetters} from "vuex";
import Checkout from "../components/shop/Checkout";
import LudiFooter from "@/components/LudiFooter";


const backgroundsPic = {
  pic_sm: require("@/assets/background-pg-xl-white.png"),
  pic_lg: require("@/assets/background-pg-xl-white.png")
}

export default {
  name: "BaseLayout",
  components: {LudiFooter, Checkout, Notification, Menu},
  mixins: [AuthPlayerNeeded, MessageMixin, NavigationMixin, NotificationMixin, LayoutMixin, GameInPlayMixin, MercureMixin, AuthPlayerMixin],
  data: () => ({
    drawer: true,
    scrolled: false,
  }),
  methods: {},
  created() {
    console.log('App is launched');
    //this.onScroll();
    // this.getMe().catch(e => {
    //   if (e.status === 500 || e.response.status === 500) {
    //     this.logout();
    //   }
    // });
    // this.getGamesInplay();
    if (!this.mercureStarted && this.playerId) {
      this.setupMercureNotification(this.playerId);
    }

  },
  computed: {
    ...mapGetters({isWritingGame: 'game_write/isWritingGame'}),
    backgroundsPicture() {
      return backgroundsPic;
    },
    isIOS() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    hideOnScroll() {
      return !this.isIOS && this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    'playerId': function () {
      if (!this.mercureStarted && this.playerId) {
        this.setupMercureNotification(this.playerId);
      }
    },
    'mercureStarted': function () {
      if (!this.mercureStarted && this.playerId) {
        this.setupMercureNotification(this.playerId);
      }
    }
  }

}
</script>

<style scoped>
.show-over-avatar {
  z-index: 999999 !important;
}

.show-over-avatar-over {
  z-index: 9999999 !important;
}
</style>
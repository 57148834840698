<template>
    <v-hover class="pa-0 ma-0">
        <v-card flat tile class="pa-0 ma-0" slot-scope="{ hover }">
            <v-img :src="url" :height="height">
                <v-expand-transition>
                    <v-row dense v-if="hover"
                           align-content="center" justify="center"
                           class="transition-fast-in-fast-out ludi-blue v-card--reveal white--text fill-height pa-0 ma-0"
                    >
                        <v-col cols="12" class="text-center">
                            <span class="patchwork_text"
                                  :class="small?'patchwork_text_small':'patchwork_text'">{{text}}</span>
                            <v-btn  v-if="showHandler" class="white--text ludi-orange" @click="showHandler">En savoir plus</v-btn>
                        </v-col>
                    </v-row>
                </v-expand-transition>
            </v-img>
        </v-card>
    </v-hover>
</template>

<script>
    export default {
        name: "LudiImg",
        props: {
            src: {
                type: String,
                required: true,
                default: ""
            },
            text: {
                type: String,
                default: ""
            },
            small: {
                type: Boolean,
                default: false
            },
            height: {
                default: null
            },
            external: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                hover: false,
            }
        },
        computed: {
            url: function () {
                if (!this.external) {
                    return '127.0.0.1:8000' + this.src;
                } else {
                    return this.src;
                }
            }
        },
        created() {
            if (!this.src) {
                this.hover = true;
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
    .patchwork_text {
        display: block;
        max-height: 2.1em;
        margin-bottom: 20%;
        margin-bottom: 1.3em;
        color: white;
        font-size: 2.1em;
        font-weight: 700;
        line-height: 1;
        opacity: 1;
    }

    .patchwork_text_small {
        display: block;
        max-height: 1.5em;
        margin-bottom: 1.0em;
        color: white;
        font-size: 1.6em;
        font-weight: 600;
        line-height: 0.8;
        opacity: 1;
    }
</style>